import { provider } from 'react-ioc';
import { useRoutes } from 'react-router-dom';
import { compose } from '../../utils/compose/compose';
import { observer } from 'mobx-react';
import { DashboardPage } from './pages/dashboard';
import { DashboardStore } from './stores/dashboard.store';
import { DashboardService } from './services/dashboard.service';

const DashboardModulePure = provider()(() => {
  return useRoutes([
    {
      path: '/',
      index: true,
      Component: DashboardPage,
    },
  ])
})

DashboardModulePure.register(DashboardStore);
DashboardModulePure.register(DashboardService);

export const DashboardModule = compose<{}, {}>(observer)(DashboardModulePure)
