import { compose } from "../../../utils/compose/compose";
import { observer } from "mobx-react";
import { Box, Grid, IconButton, Paper, useTheme } from "@mui/material";
import React from "react";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

const SettingPagePure = () => {
    const theme = useTheme();
    const colorMode = React.useContext(ColorModeContext);

    return (
        <Grid item xs={10} md={8} lg={6}>
            <Paper sx={{
                p: 2,
                height: 540,
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'background.default',
                        color: 'text.primary',
                        borderRadius: 1,
                        p: 3,
                    }}
                >
                    {theme.palette.mode} mode
                    <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
                        {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                    </IconButton>
                </Box>
            </Paper>
        </Grid>
    )
}

export const SettingPage = compose<{}, {}>(observer)(SettingPagePure)
