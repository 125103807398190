import { provider } from 'react-ioc';
import { compose } from '../../utils/compose/compose';
import { observer } from 'mobx-react';
import { useRoutes } from 'react-router-dom';
import { LoginPage } from './pages/login';
import { AuthStore } from './stores/auth.store';
import { AuthService } from './services/auth.service';

const AuthModulePure = provider()(() => {
  return useRoutes([
    {
      path: '/',
      index: true,
      Component: LoginPage
    }
  ])
})

AuthModulePure.register(AuthService);
AuthModulePure.register(AuthStore);

export const AuthModule = compose<{}, {}>(observer)(AuthModulePure)
