import { makeAutoObservable } from 'mobx';

interface PaginationProps {
  total: PaginationModel["total"];
  lastPage: PaginationModel["lastPage"];
  currentPage: PaginationModel["currentPage"];
  perPage: PaginationModel["perPage"];
  prev: PaginationModel["prev"];
  next: PaginationModel["next"];
}

export class PaginationModel {
  total: number = 0;
  lastPage: number = 0;
  currentPage: number = 0;
  perPage: number = 25;
  prev: number | null = null;
  next: number | null = null;

  constructor(props: PaginationProps) {
    this.total = props.total;
    this.lastPage = props.lastPage;
    this.currentPage = props.currentPage;
    this.perPage = props.perPage;
    this.prev = props.prev;
    this.next = props.next;

    makeAutoObservable(this);
  }
}