import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from 'draft-js'

type Props = {
    onChange: (v: string) => void,
    initHtmlText?: string;
}

export function EditorComponent(props: Props) {
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
    const editorToolbarOptions = {
        options: [
            'inline',
            'blockType',
            'fontSize',
            'list',
            'textAlign',
            'colorPicker',
            'link',
            'emoji',
            'image',
            'remove',
            'history',
        ],
    };

    useEffect(() => {
        if (props.initHtmlText) {
            const blocksFromHtml = htmlToDraft(props.initHtmlText);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            setEditorState(EditorState.createWithContent(contentState));
        }
    }, [])


    const onEditorStateChange = (editorState: EditorState) => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setEditorState(editorState);
        props.onChange(html);
    }

    return (
        <Paper sx={{
            p: 2
        }}
        elevation={2}
        >
            <Editor
                editorState={editorState}
                toolbar={editorToolbarOptions}
                onEditorStateChange={onEditorStateChange}
            />
        </Paper>

    )
}
