import React, { FC } from 'react';
import { NavLink as ReactNav } from 'react-router-dom';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DashboardIcon from '@mui/icons-material/Dashboard';


const items = [
    {
        id: 1,
        name: 'Dashboard',
        icon: <DashboardIcon />,
        path: '/'
    },
    {
        id: 2,
        name: 'Admins',
        icon: <AdminPanelSettingsIcon />,
        path: '/admin'
    },
    {
        id: 3,
        name: 'Tenants',
        icon: <CorporateFareIcon />,
        path: '/tenant'
    },
    {
        id: 4,
        name: 'Consumers',
        icon: <PeopleIcon />,
        path: '/consumer'
    }
]

const NavLink = React.forwardRef<HTMLAnchorElement, any>((props, ref) => (
  <ReactNav
    ref={ref}
    {...props}
    className={({isActive}) => isActive ? props.className + ' Mui-selected' : props.className}
    end
  />
))

export const DashboardListItems:FC = () => {
    return (
        <React.Fragment>
            {items.map((item, idx) => {
                return (
                    <ListItemButton
                        component={NavLink}
                        to={item.path}
                        key={item.id}
                    >
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                    </ListItemButton>
                )
            })}
        </React.Fragment>
    )
}
