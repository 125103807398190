import React, { FC, useEffect } from "react";
import { compose } from "../../../utils/compose/compose";
import { observer } from "mobx-react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import TableContainer from "@mui/material/TableContainer";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { AlertDeleteDialogSlide } from "../../../components/dialog/alert.delete.dialog.slide";
import { SearchInputField } from "../../../components/input/search.input";
import { useInstance } from 'react-ioc';
import { TenantStore } from '../stores/tenants.store';
import { ScheduleConfigDialog } from '../components/dialog/schedule-config.dialog';
import { useSnackbar } from '../../../common/providers/snackbar.provider';


const TenantPagePure: FC = () => {
  const showSnackbar = useSnackbar();
  const tenantStore = useInstance<TenantStore>(TenantStore);
  const navigate = useNavigate();
  const { tenants, error, isLoading } = tenantStore;

  useEffect(() => {
    (async () => {
      await tenantStore.init()
    })()
  }, [tenantStore]);

  useEffect(() => {
    if (error) {
      showSnackbar('error', error)
    }
  }, [error]);

  const handleChangePage = async (event: unknown, newPage: number) => {
    try {
      tenantStore.meta.currentPage = newPage;
      await tenantStore.fetchTenants();
    } catch (e) {
      const err = e as any as Error;
      showSnackbar('error', err?.message);
    }
  };

  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      tenantStore.meta.perPage = parseInt(event.target.value, 10);
      tenantStore.meta.currentPage = 0;
      await tenantStore.fetchTenants();
    } catch (e) {
      const err = e as any as Error;
      showSnackbar('error', err?.message);
    }
  };

  const onHandleDelete = async (id: number) => {
    await tenantStore.deleteTenant(id);

    if (!error) {
      showSnackbar('success', 'Tenant successfully deleted!')
    }
  }

  const onHandleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    tenantStore.tenantSearch = e.target.value;
    await tenantStore.fetchTenants();
  }

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Paper sx={{
        p: 2,
        height: 540,
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Typography component='h2' variant='h5' color='primary' gutterBottom >Tenants</Typography>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '5px 0'
        }}>
          <SearchInputField onChange={onHandleSearch} />
          <Button variant='contained' onClick={() => navigate('create') }>
            Create tenant
          </Button>
        </Box>
        <TableContainer>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Tenant Id</TableCell>
                <TableCell>Data Created</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? <TableRow>
                  <TableCell align='center' colSpan={6} >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
                : tenants.length
                  ? tenants.map((tenant) => (
                    <TableRow key={tenant.id}>
                      <TableCell>{tenant.name}</TableCell>
                      <TableCell>{tenant.tenantId ?? '-'}</TableCell>
                      <TableCell>{tenant.createdAt}</TableCell>
                      <TableCell style={{ width: '5%' }}>
                        <ScheduleConfigDialog
                          key={`${tenant.id}-schedule-config-dialog`}
                          size='small'
                          iconColor='primary'
                          idx={tenant.id}
                          context={tenant.name}
                        />
                      </TableCell>
                      <TableCell style={{ width: '5%' }}>
                        <IconButton
                          size='small'
                          onClick={() => navigate(`${tenant.id}`)}
                        >
                          <EditIcon fontSize='small' color='warning' />
                        </IconButton>
                      </TableCell>
                      <TableCell style={{ width: '5%' }}>
                        <AlertDeleteDialogSlide
                          size='small'
                          iconColor='error'
                          idx={tenant.id}
                          deleteHandler={onHandleDelete}
                          context={tenant.name} />
                      </TableCell>
                    </TableRow>
                  ))
                  : <TableRow>
                    <TableCell align='center' colSpan={5} >
                      <Typography component='h5' variant='h5' color='blueviolet' gutterBottom >Nothing found</Typography>
                    </TableCell>
                  </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 35]}
          component="div"
          count={tenantStore.meta.total}
          page={tenantStore.meta.currentPage}
          onPageChange={handleChangePage}
          rowsPerPage={tenantStore.meta.perPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Grid>
  )
}

export const TenantPage = compose<{}, {}>(observer)(TenantPagePure)
