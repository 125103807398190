import React, { FC, useEffect } from 'react';
import { compose } from '../../../utils/compose/compose';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';
import { ConsumerStore } from '../stores/consumer-list.store';
import { TenantStore } from '../../tenant/stores/tenants.store';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { ConsumerApiNS } from '../services/consumer-api.type';
import * as Yup from 'yup';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel, MenuItem,
  Paper, Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { BreadcrumbsComponent } from '../../../components/breadcrumbs/breadcrumbs';
import { useSnackbar } from '../../../common/providers/snackbar.provider';

const ConsumerDetailPure: FC = () => {
  const showSnackbar = useSnackbar();
  const [consumerStore, tenantStore] = useInstances<[ConsumerStore, TenantStore]>(ConsumerStore, TenantStore);
  const { id } = useParams<"id">();
  const navigate = useNavigate();
  const { error, isLoading } = consumerStore;

  const formik = useFormik({
    initialValues: {
      name: '',
      surname: '',
      email: '',
      auth0Password: '',
      photo: undefined,
      status: ConsumerApiNS.ConsumerStatus.APPROVED,
      url: '',
      birthday: '',
      gender: ConsumerApiNS.Gender.OTHER,
      tenantId: '',
      submit: null
    },
    validationSchema: Yup.object({
      name: Yup
        .string()
        .max(100)
        .optional()
        .nullable()
        .notRequired(),
      surname: Yup
        .string()
        .max(100)
        .nullable()
        .default(null)
        .notRequired(),
      email: Yup
        .string()
        .max(100)
        .required('Email is required'),
      auth0Password: Yup
        .string()
        .max(100)
        .nullable()
        .optional()
        .notRequired(),
      photo: Yup
        .mixed()
        .optional(),
      status: Yup
        .string()
        .max(100)
        .required('Status is required'),
      url: Yup
        .string()
        .nullable()
        .default(null)
        .notRequired(),
      birthday: Yup
        .date()
        .optional()
        .default(null)
        .notRequired(),
      gender: Yup
        .string()
        .max(100)
        .required('Gender is required'),
      tenantId: Yup
        .number()
        .required('Tenant is required'),
    }),
    onSubmit: async (values, helpers) => {
      const { name, surname, email, auth0Password, photo, status, birthday, gender, tenantId } = values;

      const body: ConsumerApiNS.ConsumerUpdateDTO = {
        name,
        surname,
        email,
        birthday,
        gender,
        status,
        tenantId
      }

      if (photo) {
        body.photo = photo;
      }

      if (auth0Password) {
        body.auth0Password = auth0Password;
      }

      if (id) {
        await consumerStore.updateConsumer(id, body);

        if (!error) {
          showSnackbar('success', 'Consumer successfully updated');
          await navigate('/consumer')
        }
      }
    }
  });

  const onHandleStatus = (event: SelectChangeEvent) => {
    formik.setFieldValue('status', event.target.value);
  }

  const onHandleGender = (event: SelectChangeEvent) => {
    formik.setFieldValue('gender', event.target.value);
  }

  const onHandleTenant = (event: SelectChangeEvent) => {
    formik.setFieldValue('tenantId', event.target.value);
  }

  useEffect(() => {
    if (id) {
      (async () => {
        const consumer = await consumerStore.fetchConsumerById(id);

        if (consumer) {
          const {name, surname, email, auth0Password, photo, status, birthday, gender, tenant} = consumer;
          formik.setFieldValue('name', name);
          formik.setFieldValue('surname', surname);
          formik.setFieldValue('email', email);
          formik.setFieldValue('auth0Password', auth0Password);
          formik.setFieldValue('status', status);
          formik.setFieldValue('birthday', birthday);
          formik.setFieldValue('gender', gender);
          formik.setFieldValue('tenantId', tenant?.id);
          if (photo) formik.setFieldValue('photo', photo);
        }
      })()
    }
  }, [id, consumerStore])

  useEffect(() => {
    if (error) showSnackbar('error', error);
  }, [error]);

  return (
    <Grid item xs={10} md={8} lg={6}>
      <BreadcrumbsComponent breadcrumbs={[
        {
          name: 'Consumers',
          link: '/consumer'
        },
        {
          name: `${formik.values.name ?? formik.values.email}`,
          link: `/consumer/${id}`
        }
      ]} />
      <Paper sx={{
        p: 2,
        marginTop: 5,
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Typography component='h2' variant='h5' color='primary' gutterBottom > Update consumer</Typography>
        <Box sx={{ padding: 5 }}>
          <form
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={2}>
                <InputLabel
                  sx={{
                    display: "flex",
                    fontWeight: 700
                  }}
                >
                  Name
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={10}>
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  error={!!(formik.touched.name && formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={2} key='surname_label'>
                <InputLabel
                  sx={{
                    display: "flex",
                    fontWeight: 700
                  }}
                >
                  Surname
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={10} key='surname'>
                <TextField
                  id="surname"
                  name="surname"
                  label="Surname"
                  error={!!(formik.touched.surname && formik.errors.surname)}
                  helperText={formik.touched.surname && formik.errors.surname}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.surname}
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <InputLabel
                  sx={{
                    display: "flex",
                    fontWeight: 700
                  }}
                >
                  Email
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={10}>
                <TextField
                  required
                  id="email"
                  name="email"
                  label="Email"
                  type="email"
                  error={!!(formik.touched.email && formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <InputLabel
                  sx={{
                    display: "flex",
                    fontWeight: 700
                  }}
                >
                  Password
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={10}>
                <TextField
                  required
                  id="auth0Password"
                  name="auth0Password"
                  label="Password"
                  type="password"
                  error={!!(formik.touched.auth0Password && formik.errors.auth0Password)}
                  helperText={formik.touched.auth0Password && formik.errors.auth0Password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.auth0Password}
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <InputLabel
                  sx={{
                    display: "flex",
                    fontWeight: 700
                  }}
                >
                  Photo
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  component='label'
                >
                  Upload Logo
                  <input
                    type='file'
                    id='photo'
                    name='photo'
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const files = e.target?.files;
                      if (files && files.length) {
                        formik.setFieldValue('photo', files[0]);
                      }
                    }}
                    hidden
                  />
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Avatar
                  alt={formik.values.email}
                  src={formik.values.photo}
                  sx={{ width: 56, height: 56 }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <InputLabel
                  sx={{
                    display: "flex",
                    fontWeight: 700
                  }}
                >
                  Status
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={10}>
                <FormControl fullWidth>
                  <InputLabel id="status">Status</InputLabel>
                  <Select
                    labelId="status"
                    id="status"
                    name="status"
                    value={formik.values.status}
                    label="Status"
                    error={!!(formik.touched.status && formik.errors.status)}
                    onBlur={formik.handleBlur}
                    onChange={onHandleStatus}
                    disabled={isLoading}
                  >
                    {Object.entries(ConsumerApiNS.ConsumerStatus).map(([key, value]) => (
                      <MenuItem value={key}>{value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} key='url_label'>
                <InputLabel
                  sx={{
                    display: "flex",
                    fontWeight: 700
                  }}
                >
                  Url
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={10} key='url'>
                <TextField
                  id="url"
                  name="url"
                  label="Url"
                  error={!!(formik.touched.url && formik.errors.url)}
                  helperText={formik.touched.url && formik.errors.url}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.url}
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={2} key='gender_label'>
                <InputLabel
                  sx={{
                    display: "flex",
                    fontWeight: 700
                  }}
                >
                  Gender
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={10} key='gender'>
                <FormControl fullWidth>
                  <InputLabel id="gender">Gender *</InputLabel>
                  <Select
                    required
                    labelId="gender"
                    id="gender"
                    value={formik.values.gender}
                    label="Gender *"
                    error={!!(formik.touched.gender && formik.errors.gender)}
                    onBlur={formik.handleBlur}
                    onChange={onHandleGender}
                    disabled={isLoading}
                  >
                    {Object.entries(ConsumerApiNS.Gender).map(([key, value]) => (
                      <MenuItem value={key}>{value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} key='birthday_label'>
                <InputLabel
                  sx={{
                    display: "flex",
                    fontWeight: 700
                  }}
                >
                  Birthday
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={10} key='birthday'>
                <TextField
                  id="birthday"
                  name="birthday"
                  label=""
                  type="date"
                  error={!!(formik.touched.birthday && formik.errors.birthday)}
                  helperText={formik.touched.birthday && formik.errors.birthday}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.birthday}
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <InputLabel
                  sx={{
                    display: "flex",
                    fontWeight: 700
                  }}
                >
                  Tenant
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={10}>
                <FormControl fullWidth>
                  <InputLabel id="tenant">Tenant</InputLabel>
                  <Select
                    labelId="tenant"
                    id="tenant"
                    value={formik.values.tenantId}
                    label="Tenant"
                    error={!!(formik.touched.tenantId && formik.errors.tenantId)}
                    onBlur={formik.handleBlur}
                    onChange={onHandleTenant}
                    disabled={isLoading}
                  >
                    {tenantStore.tenants.map(tenant => (
                      <MenuItem
                        key={tenant.id}
                        value={tenant.id}
                      >
                        {tenant.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {formik.errors.submit && (
                <Typography
                  color="error"
                  sx={{ mt: 3 }}
                  variant="body2"
                >
                  {formik.errors.submit}
                </Typography>
              )}
              <Grid item xs={12} sm={4}>
                <Button variant="contained" type="submit" disabled={isLoading} >
                  Update {isLoading && <CircularProgress />}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
    </Grid>
  )
}

export const ConsumerDetail = compose<{}, {}>(observer)(ConsumerDetailPure)
