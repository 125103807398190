import { makeAutoObservable } from "mobx";
import { DesignStore } from "./design.store";
import { WritingStore } from "./writing.store";

export class TenantModel {
    id?: number;
    name: string = '';
    tenantId?: string = '';
    owner: string = ''
    scope: string = ''
    design?: DesignStore;
    writing?: WritingStore;
    createdAt?: string;
    updatedAt?: string;

    constructor() {
        makeAutoObservable(this);
    }
}
