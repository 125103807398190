import React, { useState } from 'react';
import jwtDecode from 'jwt-decode';

interface AuthContextProps {
  token: string | null;
  login: (token: string) => void;
  logout: () => void;
  isAuthenticated: () => boolean;
}

export const AuthContext = React.createContext<AuthContextProps>({
  token: '',
  login: () => {},
  logout: () => {},
  isAuthenticated: () => false
});

export const AuthProvider = ({ children }: any) => {
  const [token, setToken] = useState(localStorage.getItem('token') || null);

  const login = (token: string) => {
    setToken(token);
    localStorage.setItem('token', token);
  };

  const logout = () => {
    setToken(null);
    localStorage.removeItem('token');
  };

  const isAuthenticated = () => {
    if (token) {
      const decoded: { exp: number } = jwtDecode(token);
      return decoded.exp > Date.now() / 1000;
    }
    return false;
  }

  return (
    <AuthContext.Provider value={{ token, login, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
}
