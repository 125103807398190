import React, {useEffect, useState} from "react";
import { MuiColorInput } from "mui-color-input";
import {compose} from "../../utils/compose/compose";
import {observer} from "mobx-react";

type Props = {
    value: string,
    onChange: (value: string) => void,
}

const ColorPickerInputPure = (props: Props) => {
    const [value, setValue] = useState(props.value);

    const handleChange = (value: any) => {
        setValue(value);
        props.onChange(value);
    }

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    return (
        <React.Fragment>
            <MuiColorInput value={value} onChange={handleChange} format='hex' />
        </React.Fragment>
    )
}

export const ColorPickerInput = compose<Props, Props>(observer)(ColorPickerInputPure)