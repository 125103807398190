import React, { createContext, useContext, useState, PropsWithChildren } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '../../components/alert';
import { AlertColor } from '@mui/material/Alert/Alert';

const SnackbarContext = createContext((type: AlertColor, message: string) => {});

export const SnackbarProvider = ({ children }: PropsWithChildren) => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [type, setType] = useState<AlertColor>('error')

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setMessage('');
  };

  const showSnackbar = (type: AlertColor, message: string) => {
    setMessage(message);
    setType(type);
    setOpen(true);
  };

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={message}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          severity={type}
          onClose={handleClose}
        >{message}</Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};
