import { makeAutoObservable, runInAction } from 'mobx';
import { ConsumerModel } from "../models/consumer.model";
import { ConsumerService } from "../services/consumer.service";
import { ConsumerApiNS } from "../services/consumer-api.type";
import { PaginationModel } from '../models/pagination.model';
import { inject } from 'react-ioc';

interface State {
  loading: boolean;
  error: string;
  data: ConsumerModel[];
}

export class ConsumerStore {
  private readonly consumerService= inject<ConsumerService>(this, ConsumerService);

  private state: State = {
    loading: false,
    error: '',
    data: [],
  }

  meta: PaginationModel = {
    total: 0,
    lastPage: 0,
    currentPage: 0,
    perPage: 10,
    next: null,
    prev: null
  }

  consumerSearch: string = '';

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public get consumers() {
    return this.state.data;
  }

  public get isLoading() {
    return this.state.loading;
  }

  public get error() {
    return this.state.error;
  }

  public async sendPushNotificationToCustomer(id: number) {
    try {
      runInAction(() => this.state.error = '');
      await this.consumerService.sendPushNotification(id);
    } catch (e: any) {
      runInAction(() => this.state.error = e?.response?.data?.message || e?.message);
    }
  }

  public async deleteConsumer(id: number) {
    try {
      runInAction(() => {
        this.state.error = '';
        this.state.loading = true;
      })
      await this.consumerService.deleteConsumer(id);
      runInAction(() => {
        this.state.data = this.consumers.filter(consumer => consumer.id !== id);
      })
    } catch (e: any) {
      runInAction(() => {
        this.state.error = e?.response?.data?.message || e?.message;
      })
    } finally {
      runInAction(() => this.state.loading = false);
    }
  }

  public async createConsumer(body: ConsumerApiNS.ConsumerCreateDTO) {
    try {
      runInAction(() => {
        this.state.loading = true;
        this.state.error = '';
      });
      const consumer: ConsumerModel = await this.consumerService.createConsumer(body);
      this.state.data.push(consumer);
    } catch (e: any) {
      runInAction(() => this.state.error = e?.response?.data?.message || e?.message)
    } finally {
      runInAction(() => this.state.loading = false);
    }
  }

  public async updateConsumer(id: string, data: ConsumerApiNS.ConsumerUpdateDTO) {
    try {
      runInAction(() => {
        this.state.loading = true;
        this.state.error = '';
      });
      await this.consumerService.updateConsumer(id, data);
    } catch (e: any) {
      runInAction(() => this.state.error = e?.response?.data?.message || e?.message);
    } finally {
      runInAction(() => this.state.loading = false);
    }
  }

  public async fetchConsumerById(id: string) {
    try {
      runInAction(() => {
        this.state.error = '';
        this.state.loading = true;
      })
      return this.consumerService.getConsumerById(id);
    } catch (e: any) {
      runInAction(() => this.state.error = e?.response?.data?.message || e?.message);
    } finally {
      runInAction(() => this.state.loading = false);
    }
  }

  public async fetchCustomers() {
    runInAction(() => {
      this.state.loading = true;
      this.state.error = '';
      this.state.data = [];
    })
    try {
      const response =  await this.consumerService.getConsumers({
        search: this.consumerSearch,
        page: this.meta.currentPage,
        perPage: this.meta.perPage,
      });
      runInAction(() => {
        this.state.data = response.data;
        this.meta = response.meta;
      });
    } catch (e: any) {
      runInAction(() => this.state.error = e?.response?.data?.message || e?.message);
    } finally {
      runInAction(() => this.state.loading = false);
    }
  }
}
