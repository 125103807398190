import { makeAutoObservable, runInAction } from 'mobx';
import { DashboardService } from "../services/dashboard.service";
import { ConsumerCountByStatusModel } from "./consumer-count-by-status.model";
import moment from "moment";
import { inject } from 'react-ioc';

interface State {
    loading: boolean;
    error: string;
    data: ConsumerCountByStatusModel;
}

export class DashboardStore {
    dashboardService = inject<DashboardService>(this, DashboardService);

    private state: State = {
        loading: false,
        error: '',
        data: {
            APPROVED: 0,
            PENDING: 0,
            BLOCKED: 0
        },
    }
    constructor() {
        makeAutoObservable(this, undefined, { autoBind: true });
    }

    get data() {
        return this.state.data;
    }

    public async init() {
        const from = moment('2023-01-01 00:00:00').startOf('month').toISOString();
        const to = moment().endOf('month').toISOString();
        try {
            const response = await this.dashboardService.getConsumerCountByStatuses({ from, to });
            runInAction(() => {
                this.state.data = response
            })
        } catch (e) {
            const typedError = e as any
            runInAction(() => {
                this.state.error = typedError?.response?.data?.message;
            })
        }
    }
}
