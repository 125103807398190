import { useRoutes } from 'react-router-dom';
import { compose } from '../../utils/compose/compose';
import { observer } from 'mobx-react';
import { UserList } from './pages/user.list';
import { UserDetail } from './pages/user.detail';
import { UserCreate } from './pages/user.create';
import { provider } from 'react-ioc';
import { UserStore } from './stores/user.store';
import { UserService } from './services/user.service';

const UserModulePure = provider()(() => {
  return useRoutes([
    {
      path: '/',
      index: true,
      Component: UserList,
    },
    {
      path: 'create',
      Component: UserCreate
    },
    {
      path: ':id',
      Component: UserDetail
    }
  ])
})

UserModulePure.register(UserStore);
UserModulePure.register(UserService);

export const UserModule = compose<{}, {}>(observer)(UserModulePure)
