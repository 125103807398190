import { TenantModel } from "./tenant.model";
import { makeAutoObservable, runInAction } from 'mobx';
import { TenantService } from "../services/tenant.service";
import { TenantApiNS } from "../services/tenant.api.type";
import { PaginationModel } from '../../consumer/models/pagination.model';
import { inject } from 'react-ioc';

interface State {
    loading: boolean;
    error: string;
    data: TenantModel[];
}

export class TenantStore {
    private readonly tenantService = inject<TenantService>(this, TenantService);

    state: State = {
        loading: false,
        error: '',
        data: [],
    }

    meta: PaginationModel = {
        total: 0,
        lastPage: 0,
        currentPage: 0,
        perPage: 10,
        next: null,
        prev: null
    }

    tenantSearch: string = ''

    constructor() {
        makeAutoObservable(this, undefined, { autoBind: true });
    }

    public get tenants() {
        return this.state.data;
    }

    public get error() {
        return this.state.error;
    }

    public get isLoading() {
        return this.state.loading;
    }

    public set search(search: string) {
        this.tenantSearch = search
    }

    *init() {
        yield this.fetchTenants()
    }

    public async fetchTenants() {
        try {
            runInAction(() => this.state.loading);
            const response = await this.tenantService.getTenants({
                search: this.tenantSearch,
                page: this.meta.currentPage,
                perPage: this.meta.perPage,
            })

            runInAction(() => {
                this.state.data = response.data;
                this.meta = response.meta;
            });
        } catch (e: any) {
            runInAction(() => this.state.error = e?.response?.data?.message || e?.message);
        } finally {
            runInAction(() => this.state.loading = false);
        }
    }

    public async fetchTenantById(id: string) {
        return this.tenantService.getTenantById(id);
    }

    public async postTenant(data: TenantApiNS.CreateTenantDTO) {
        try {
            const tenant = await this.tenantService.createTenant(data);
            this.state.data.push(tenant);
        } catch (e: any) {
            runInAction(() => this.state.error = e?.response?.data?.message || e?.message);
        }
    }

    public *updateTenant(id: string, body: TenantApiNS.UpdateTenantDTO) {
        try {
            runInAction(() => this.state.loading = true);
            yield this.tenantService.updateTenant(id, body);
        } catch (e: any) {
            runInAction(() => this.state.error = e?.response?.data?.message || e?.message);
        } finally {
            runInAction(() => this.state.loading = false);
        }
    }

    public async deleteTenant(id: number) {
        try {
            await this.tenantService.deleteTenant(id);
            runInAction(() =>
              this.state.data = this.state.data.filter(tenant => tenant.id !== id)
            );
        } catch (e: any) {
            runInAction(() => this.state.error = this.state.error = e?.response?.data?.message || e?.message);
        }
    }
}
