import { makeAutoObservable } from "mobx";
import moment from "moment";

interface TenantProps {
    id: TenantModel['id'];
    name: TenantModel['name'];
    owner: TenantModel['owner'];
    scope: TenantModel['scope'];
    tenantId: TenantModel['tenantId'];
    createdAt: TenantModel['createdAt'];
    updatedAt: TenantModel['updatedAt'];
    design?: TenantModel['design'];
    writing?: TenantModel['writing'];
}

export interface TenantDesignType {
    id: number;
    tenantId: TenantModel['id'];
    logoBase64: string;
    backgroundColor: string;
    headerColor: string;
    textColor: string;
    createdAt: string;
    updatedAt: string;
}

export interface TenantWritingType {
    id: number;
    tenantId: TenantModel['id'];
    bodyText: string;
    createdAt: string;
    updatedAt: string;
}

export class TenantModel {
    id: number;
    name: string;
    tenantId: string | null;
    owner?: string | null;
    scope?: string | null;
    createdAt?: string;
    updatedAt?: string;
    design?: TenantDesignType;
    writing?: TenantWritingType;

    constructor(props: TenantProps) {
        this.id = props.id;
        this.name = props.name;
        this.tenantId = props.tenantId;
        this.owner = props.owner;
        this.scope = props.scope;
        this.createdAt = moment(props.createdAt).format('LLLL');
        this.updatedAt = moment(props.updatedAt).format('LLLL');
        this.design = props.design;
        this.writing = props.writing;
        makeAutoObservable(this);
    }
}
