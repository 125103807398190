import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { SearchInputField } from '../../../components/input/search.input';
import TableContainer from '@mui/material/TableContainer';
import EditIcon from '@mui/icons-material/Edit';
import { AlertDeleteDialogSlide } from '../../../components/dialog/alert.delete.dialog.slide';
import { compose } from '../../../utils/compose/compose';
import { observer } from 'mobx-react';
import { useInstance } from 'react-ioc';
import { UserStore } from '../stores/user.store';
import { useSnackbar } from '../../../common/providers/snackbar.provider';

const UserListPure: FC = () => {
  const showSnackbar = useSnackbar();
  const navigate = useNavigate();
  const userStore = useInstance<UserStore>(UserStore);
  const { users, error, isLoading } = userStore;

  useEffect(() => {
    (async () => {
      await userStore.init()
    })()
  }, [userStore]);

  useEffect(() => {
    if (error) {
      showSnackbar('error', error);
    }
  }, [error])

  const onHandleDelete = async (id: number) => {
    await userStore.deleteUser(id);

    if (error) {
      return showSnackbar('error', error)
    }
    showSnackbar('success', 'Admin successfully removed');
  }

  const onHandleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    userStore.userSearch = e.target.value;
    await userStore.fetchUsers();

    if (error) {
      return showSnackbar('error', error)
    }
  }

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Paper sx={{
        p: 2,
        height: 540,
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Typography component='h2' variant='h5' color='primary' gutterBottom >Admins</Typography>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '5px 0'
        }}>
          <SearchInputField onChange={onHandleSearch} />
          <Button variant='contained' onClick={() => navigate('create')}>
            Create user
          </Button>
        </Box>
        <TableContainer>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Data Created</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? <CircularProgress />
                : users.length
                  ? users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.createdAt}</TableCell>
                      <TableCell style={{ width: '5%' }}>
                        <IconButton
                          size='small'
                          onClick={() => navigate(`${user.id}`)}
                        >
                          <EditIcon fontSize='small' color='warning' />
                        </IconButton>
                      </TableCell>
                      <TableCell style={{ width: '5%' }}>
                        <AlertDeleteDialogSlide
                          size='small'
                          iconColor='error'
                          idx={user.id}
                          deleteHandler={onHandleDelete}
                          context={user.name ?? user.email} />
                      </TableCell>
                    </TableRow>
                  ))
                  : <TableRow>
                    <TableCell align='center' colSpan={5} >
                      <Typography component='h5' variant='h5' color='blueviolet' gutterBottom >Nothing found</Typography>
                    </TableCell>
                  </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  )
}

export const UserList = compose<{}, {}>(observer)(UserListPure)
