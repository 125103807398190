import { provider } from 'react-ioc';
import { useRoutes } from 'react-router-dom';
import { observer } from 'mobx-react';
import { compose } from '../../utils/compose/compose';
import { TenantPage } from './pages/tenant-list';
import { TenantCreatePage } from './pages/tenant-create';
import { TenantUpdatePage } from './pages/tenant-details';
import { TenantStore } from './stores/tenants.store';
import { TenantService } from './services/tenant.service';
import { ScheduleConfigService } from './services/schedule-config.service';
import { ScheduleConfigStore } from './stores/schedule-config.store';
import { DesignStore } from './stores/design.store';
import { WritingStore } from './stores/writing.store';
import { TenantModel } from './stores/tenant.store';

const TenantModulePure = provider()(
  () => {
    return useRoutes([
      {
        path: '/',
        index: true,
        Component: TenantPage
      },
      {
        path: 'create',
        Component: TenantCreatePage
      },
      {
        path: ':id',
        Component: TenantUpdatePage
      },
    ])
  }
)

TenantModulePure.register(TenantModel);
TenantModulePure.register(DesignStore);
TenantModulePure.register(WritingStore);
TenantModulePure.register(TenantStore);
TenantModulePure.register(TenantService);
TenantModulePure.register(ScheduleConfigStore);
TenantModulePure.register(ScheduleConfigService);

export const TenantModule = compose<{}, {}>(observer)(TenantModulePure)
