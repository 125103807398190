import { inject } from 'react-ioc';
import { ScheduleConfigService } from '../services/schedule-config.service';
import { makeAutoObservable, runInAction } from 'mobx';
import { ScheduleConfigApiNS } from '../services/schedule-config.api';

interface State {
  loading: boolean;
  error: string;
  data: ScheduleConfigApiNS.ScheduleConfigDTO | null;
}

export class ScheduleConfigStore {
  private readonly scheduleConfigService = inject<ScheduleConfigService>(this, ScheduleConfigService);

  private state: State = {
    loading: false,
    error: '',
    data: null,
  }

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public get scheduleConfigs() {
    return this.state.data;
  }

  public get isLoading() {
    return this.state.loading;
  }

  public get error() {
    return this.state.error
  }

  public async fetchScheduleConfigurations(tenantId: number) {
    try {
      this.state.loading = true;
      const scheduleConfigs = await this.scheduleConfigService.get(tenantId);
      runInAction(() => {
        this.state.data = scheduleConfigs;
      })
    } catch (e: any) {
      const error = e?.response?.data?.message ?? e.message
      runInAction(() => {
        this.state = {
          loading: false,
          data: null,
          error,
        }
      })
    } finally {
      runInAction(() => {
        this.state.loading = false;
      })
    }
  }

  public async updateScheduleConfigurations(tenantId: number, data: ScheduleConfigApiNS.ScheduleConfigDTO) {
    try {
      this.state.loading = true;
      await this.scheduleConfigService.update(tenantId, data)
    } catch (e) {
      const typedError = e as any as Error
      runInAction(() => {
        this.state = {
          loading: false,
          data: null,
          error: typedError?.message,
        }
      })
    } finally {
      runInAction(() => {
        this.state.loading = false;
      })
    }
  }
}
