import { Meta } from '../../../common/types';

export namespace ConsumerApiNS {
  export interface GetAllParams {
    search?: string;
    page?: number;
    perPage?: number;
  }

  export enum ConsumerStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    BLOCKED = 'BLOCKED'
  }

  export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER'
  }

  export interface PaginatedConsumerResponseDTO {
    meta: Meta,
    data: ConsumerResponseDTO[]
  }

  export interface ConsumerResponseDTO {
    id: number;
    tenantId: number;
    tenant: {
      id: number;
      tenantId: string;
      name: string;
    };
    email: string;
    name?: string;
    photo?: string;
    status: ConsumerStatus;
    url?: string | null;
    lastSeenDeviceId: string | null;
    lastSeenDeviceIdUpdatedAt: string | null;
    createdAt: string;
    updatedAt: string;
  }

  export interface ConsumerCreateDTO {
    name?: string;
    surname?: string;
    email: string;
    photo?: File | null;
    status: string;
    url?: string | undefined;
    birthday: string | undefined;
    gender: string | undefined
    auth0Password: string;
    tenantId: string | undefined;
  }

  export interface ConsumerUpdateDTO {
    name?: string | undefined;
    surname?: string | undefined;
    email?: string | undefined;
    photo?: File | null;
    status?: string | undefined;
    url?: string | undefined;
    birthday?: string | undefined;
    gender?: string | undefined
    auth0Password?: string | undefined;
    tenantId: string | undefined;
  }
}
