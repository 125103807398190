import { compose } from "../../../utils/compose/compose";
import { observer } from "mobx-react";
import { ConsumerStatusPie } from "../../../components/analytic/consumer-status.pie";
import { CircularProgress, Grid, Paper, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Alert, MessageState } from "../../../components/alert";
import { useInstance } from 'react-ioc';
import { DashboardStore } from '../stores/dashboard.store';

const DashboardPagePure = () => {
    const dashboardStore = useInstance<DashboardStore>(DashboardStore);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<MessageState>({
        open: false,
        severity: 'success',
        message: ''
    });

    useEffect(() => {
        try {
            setIsLoading(true);
            (async () => {
                await dashboardStore.init()
            })()
        } catch (e) {
            const err = e as any as Error;
            setError({
                open: true,
                severity: 'error',
                message: err?.message
            })
        } finally {
            setIsLoading(false);
        }
    }, [dashboardStore])

    return (
        <Grid item xs={12} md={6} lg={4}>
            <Paper sx={{
                p: 2,
                height: 500,
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Typography component='h2' variant='h5' color='primary' gutterBottom >Consumers By Statuses</Typography>
                { isLoading
                    ? <CircularProgress />
                    : <ConsumerStatusPie consumersCountByStatuses={dashboardStore.data} />
                }
            </Paper>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={error.open}
            >
                <Alert severity={error.severity}>{error.message}</Alert>
            </Snackbar>
        </Grid>
    )
}

export const DashboardPage = compose<{}, {}>(observer)(DashboardPagePure)
