import moment from "moment/moment";
import {makeAutoObservable} from "mobx";

interface UserProps {
    id: UserModel["id"];
    name: UserModel["name"];
    email: UserModel["email"];
    password?: UserModel["password"];
    createdAt: UserModel["createdAt"];
    updatedAt: UserModel["updatedAt"];
}

export class UserModel {
    id: number;
    name: string;
    email: string;
    password?: string;
    createdAt: string;
    updatedAt: string;

    constructor(props: UserProps) {
        this.id = props.id;
        this.name = props.name;
        this.email = props.email;
        this.password = props.password;
        this.createdAt = moment(props.createdAt).format('LLLL');
        this.updatedAt = moment(props.updatedAt).format('LLLL');
        makeAutoObservable(this);
    }
}