import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import packageInfo from '../../../package.json';

const VersionRoot = styled('div')({
  position: 'fixed',
  zIndex: 50,
  right: 10,
  bottom: 10,
});
const VersionTypography = styled(Typography)({
  fontSize: 10,
  fontWeight: 'bold',
  color: 'gray',
});

export const AppVersionUI = () => {
  return (
    <VersionRoot>
      <VersionTypography>{packageInfo.version}</VersionTypography>
    </VersionRoot>
  );
};