import { createBrowserRouter } from 'react-router-dom';
import { DashboardLayout } from "./layouts/dashboard/dashboard.layout";
import { LoginLayout } from "./layouts/login/login.layout";
import { ConsumerModule } from './modules/consumer/consumer.module';
import { UserModule } from './modules/user/user.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { TenantModule } from './modules/tenant/tenant.module';
import { AuthModule } from './modules/auth/auth.module';
import { SettingModule } from './modules/setting/setting.module';

export const router = createBrowserRouter([
    {
        id: 'login',
        path: "/login/*",
        Component: LoginLayout,
        children: [
            {
                index: true,
                Component: AuthModule
            }
        ]
    },
    {
        id: "root",
        path: "/",
        Component: DashboardLayout,
        children: [
            {
                index: true,
                Component: DashboardModule,
            },
            {
                path: '/consumer/*',
                Component: ConsumerModule
            },
            {
                path: '/tenant/*',
                Component: TenantModule
            },
            {
                path: '/admin/*',
                Component: UserModule
            },
            {
                path: '/settings/*',
                Component: SettingModule
            }
        ],
    }
]);
