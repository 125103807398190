export namespace ScheduleConfigApiNS {
  export enum ScheduleConfigType {
    TIMESPAN='TIMESPAN',
    SURFTIME='SURFTIME'
  }

  export interface ScheduleConfigDTO {
    type: ScheduleConfigType,
    surfTimeInMinutesUntilNextCampaignIsPushed: number,
    timespanInMinutesUntilNextCampaignIsPushed: number,
    gracePeriodInMinutesToWatchCampaign: number,
    enabled: boolean,
  }
}
