import { compose } from '../../utils/compose/compose';
import { observer } from 'mobx-react';
import { useRoutes } from 'react-router-dom';
import { ConsumerList } from './pages/consumer.list';
import { ConsumerDetail } from './pages/consumer.detail';
import { ConsumerCreate } from './pages/consumer.create';
import { provider } from 'react-ioc';
import { ConsumerStore } from './stores/consumer-list.store';
import { ConsumerModel } from './models/consumer.model';
import { ConsumerService } from './services/consumer.service';
import { TenantStore } from '../tenant/stores/tenants.store';

const ConsumerModulePure = provider()(() => {
  return useRoutes([
    {
      path: '/',
      index: true,
      Component: ConsumerList,
    },
    {
      path: 'create',
      Component: ConsumerCreate,
    },
    {
      path: ':id',
      Component: ConsumerDetail,
    },
  ])
})

ConsumerModulePure.register(ConsumerModel);
ConsumerModulePure.register(ConsumerStore);
ConsumerModulePure.register(ConsumerService);
ConsumerModulePure.register(TenantStore);

export const ConsumerModule = compose<{}, {}>(observer)(ConsumerModulePure)
