import { TenantModel } from "../../tenant/models/tenant.model";
import { makeAutoObservable } from "mobx";
import { ConsumerApiNS } from "../services/consumer-api.type";
import moment from "moment";

interface ConsumerProps {
  id: ConsumerModel['id'];
  tenant: ConsumerModel['tenant'];
  email: ConsumerModel['email'];
  name?: ConsumerModel['name'];
  surname?: ConsumerModel['surname'];
  photo?: ConsumerModel['photo'];
  status: ConsumerModel['status'];
  url?: ConsumerModel['url'];
  birthday?: ConsumerModel['birthday'];
  gender?: ConsumerModel['gender'];
  lastSeenDeviceId: ConsumerModel['lastSeenDeviceId'];
  lastSeenDeviceIdUpdatedAt: ConsumerModel['lastSeenDeviceIdUpdatedAt'];
  createdAt: ConsumerModel['createdAt'];
  updatedAt: ConsumerModel['updatedAt'];
  auth0Password?: ConsumerModel['auth0Password'];
  auth0UserId?: ConsumerModel['auth0UserId'];
}

export class ConsumerModel {
  id: number;
  tenant?: Pick<TenantModel, 'name' | 'id' | 'tenantId' >;
  email: string;
  photo?: string | undefined;
  name?: string | undefined | null;
  surname?: string | undefined | null;
  status: ConsumerApiNS.ConsumerStatus;
  url?: string | undefined | null;
  birthday?: string | undefined | null;
  gender?: ConsumerApiNS.Gender;
  lastSeenDeviceId: string | null;
  lastSeenDeviceIdUpdatedAt: string | null;
  createdAt: string;
  updatedAt: string;
  auth0Password?: string;
  auth0UserId?: string;

  constructor(props: ConsumerProps) {
    this.id = props.id;
    this.tenant = props.tenant;
    this.email = props.email;
    this.name = props.name;
    this.surname = props.surname;
    this.photo = props.photo;
    this.status = props.status;
    this.url = props.url;
    this.birthday = props.birthday;
    this.gender = props.gender;
    this.lastSeenDeviceId = props.lastSeenDeviceId;
    this.lastSeenDeviceIdUpdatedAt = props.lastSeenDeviceIdUpdatedAt;
    this.createdAt = moment(props.createdAt).format('LLLL');
    this.updatedAt = moment(props.updatedAt).format('LLLL');
    this.auth0Password = props.auth0Password;
    this.auth0UserId = props.auth0UserId;
    makeAutoObservable(this);
  }

  getFullName () {
    return `${this.name ?? ''} ${this.surname ?? ''}`;
  }
}
