import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from "./router";
import { ThemeProvider } from "@emotion/react";
import { createTheme, CssBaseline } from "@mui/material";
import { ColorModeContext } from "./modules/setting/pages/setting.page"
import { provider } from 'react-ioc';
import { AuthProvider } from './modules/auth/contexts/auth.context';
import { SnackbarProvider } from './common/providers/snackbar.provider';

function App() {
  const [mode, setMode] = React.useState<'light' | 'dark'>('light');
  const colorMode = React.useMemo(
      () => ({
        toggleColorMode: () => {
          setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        },
      }),
      [],
  );
  const theme = React.useMemo(
      () =>
          createTheme({
            palette: {
              mode,
            },
          }),
      [mode],
  );

  return (
      <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
              <CssBaseline />
                  <AuthProvider>
                      <SnackbarProvider>
                          <RouterProvider router={router} />
                      </SnackbarProvider>
                  </AuthProvider>
          </ThemeProvider>
      </ColorModeContext.Provider>
  );
}

export default provider()(App);
