import { makeAutoObservable } from "mobx";

interface Props {
    APPROVED?: number;
    PENDING?: number;
    BLOCKED?: number;
}

export class ConsumerCountByStatusModel {
    APPROVED: Props['APPROVED'] = 0;
    PENDING: Props['PENDING'] = 0;
    BLOCKED: Props['BLOCKED'] = 0;

    constructor(props: Props) {
        this.APPROVED = props.APPROVED || 0;
        this.PENDING = props.PENDING || 0;
        this.BLOCKED = props.BLOCKED || 0;
        makeAutoObservable(this);
    }
}