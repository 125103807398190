import { apiClient } from "../../../utils/api-client/api-client";
import { AuthStore } from "../../auth/stores/auth.store";
import { AxiosResponse } from "axios";
import { ConsumerCountByStatusModel } from "../stores/consumer-count-by-status.model";
import { DashboardApiNS } from "./dashboard.api.type";
import { inject } from 'react-ioc';

export class DashboardService {
  private readonly authStore = inject<AuthStore>(this, AuthStore)

    // just for showcase, in the future it could be much more :)
  async getConsumerCountByStatuses(params: DashboardApiNS.GetConsumerCountByStatusParams) {
    const res = await apiClient.get<
      DashboardApiNS.GetConsumerCountByStatusParams,
      AxiosResponse<DashboardApiNS.GetConsumerCountByStatusResponse>
    >
    (
      '/api/v1/dashboard/consumersByStatuses',
        {
          params,
          headers: {
            'Authorization': `Bearer ${this.authStore.token}`
          }
        }
    );

    return new ConsumerCountByStatusModel(res.data);
  }
}
