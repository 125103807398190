import MuiAlert, { AlertProps } from '@mui/material/Alert';
import React from "react";
import { AlertColor } from "@mui/material/Alert/Alert";

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export interface MessageState {
    open: boolean;
    severity: AlertColor;
    message: string;
}
