import { UserModel } from "../models/user.model";
import { UserService } from "../services/user.service";
import { makeAutoObservable, runInAction } from 'mobx';
import { UserApiNS } from "../services/user.api.type";
import { PaginationModel } from '../../consumer/models/pagination.model';
import { inject } from 'react-ioc';

interface State {
    loading: boolean;
    error: string;
    data: UserModel[];
}
export class UserStore {
    private readonly userService = inject<UserService>(this, UserService)

    private state: State = {
        loading: false,
        error: '',
        data: [],
    }

    meta: PaginationModel = {
        total: 0,
        lastPage: 0,
        currentPage: 0,
        perPage: 10,
        next: null,
        prev: null
    }

    userSearch: string = '';

    constructor() {
        makeAutoObservable(this, undefined, { autoBind: true });
    }

    public get users() {
        return this.state.data;
    }

    public get error() {
        return this.state.error;
    }

    public get isLoading() {
        return this.state.loading;
    }

    public set search(search: string) {
        this.userSearch = search
    }

    *init() {
        yield this.fetchUsers()
    }

    public async fetchUserById(id: string) {
        try {
            runInAction(() => this.state.loading = true);
            return this.userService.getUserById(id);
        } catch (e: any) {
            runInAction(() => this.state.error = e?.response?.message || e?.message);
        } finally {
            runInAction(() => this.state.loading = false)
        }
    }

    public async updateUser(id: string, body: UserApiNS.UserUpdateDTO) {
        try {
            runInAction(() => this.state.loading = true);
            await this.userService.updateUser(id, body);
        } catch (e: any) {
            runInAction(() => this.state.error = e?.response?.message || e?.message);
        } finally {
            runInAction(() => this.state.loading = false)
        }
    }

    public async createUser(data: UserApiNS.UserCreateDTO) {
        try {
            const user = await this.userService.createUser(data);
            this.state.data.push(user);
        } catch (e: any) {
            runInAction(() => this.state.error = e?.response?.data?.message || e?.message);
        } finally {
            runInAction(() => this.state.loading = false)
        }
    }

    public async deleteUser(id: number) {
        try {
            await this.userService.deleteUser(id);
            this.state.data = this.state.data.filter(user => user.id !== id);
        } catch (e: any) {
            runInAction(() => this.state.error = e?.response?.data?.message || e?.message)
        }
    }

    public async fetchUsers() {
        try {
            runInAction(() => {
                this.state.loading = true;
            })
            const response = await this.userService.getUsers({
                search: this.userSearch,
                page: this.meta.currentPage,
                perPage: this.meta.perPage,
            })

            runInAction(() => {
                this.state.data = response.data;
                this.meta = response.meta;
            })
        } catch (e: any) {
            runInAction(() => this.state.error = e?.response?.data?.message || e?.message);
        } finally {
            runInAction(() => {
                this.state.loading = false;
            })
        }
    }
}

const userStore = new UserStore();
export default userStore;
