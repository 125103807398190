import { ComponentEnhancer } from './compose.types';

// Taken from https://github.com/acdlite/recompose/blob/master/src/packages/recompose/compose.js
export const compose = <TInner, TOutter>(
    ...funcs: Function[]
): ComponentEnhancer<TInner, TOutter> => {
    return funcs.reduce(
        (a, b) => {
            return (...args: any[]) => a(b(...args));
        },
        (arg: any) => arg
    ) as any;
};
