import { compose } from '../../../utils/compose/compose';
import { observer } from 'mobx-react';
import { useInstance } from 'react-ioc';
import { ConsumerStore } from '../stores/consumer-list.store';
import { useNavigate } from 'react-router-dom';
import React, { FC, useEffect } from 'react';
import { ConsumerApiNS } from '../services/consumer-api.type';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { SearchInputField } from '../../../components/input/search.input';
import TableContainer from '@mui/material/TableContainer';
import { AlertNotifyDialogSlide } from '../../../components/dialog/alert.notify.dialog.slide';
import EditIcon from '@mui/icons-material/Edit';
import { AlertDeleteDialogSlide } from '../../../components/dialog/alert.delete.dialog.slide';
import { useSnackbar } from '../../../common/providers/snackbar.provider';

const ConsumerListPure: FC = () => {
  const showSnackbar = useSnackbar();
  const consumerStore = useInstance<ConsumerStore>(ConsumerStore);
  const navigate = useNavigate();
  const { consumers, error, isLoading } = consumerStore;

  const handleChangePage = async (event: unknown, newPage: number) => {
    consumerStore.meta.currentPage = newPage;
    await consumerStore.fetchCustomers();
  };

  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    consumerStore.meta.perPage = parseInt(event.target.value, 10);
    consumerStore.meta.currentPage = 0;
    await consumerStore.fetchCustomers();
  };

  useEffect(() => {
    (async () => {
      await consumerStore.fetchCustomers()
    })()
  }, [consumerStore]);

  useEffect(() => {
    if (error) {
      showSnackbar('error', error);
    }
  }, [error]);

  const getStatusIcon = (status: string) => {
    switch (status) {
      case ConsumerApiNS.ConsumerStatus.APPROVED:
        return <CheckCircleOutlineIcon color='success'/>
      case ConsumerApiNS.ConsumerStatus.PENDING:
        return <PauseCircleOutlineIcon color='warning'/>
      case ConsumerApiNS.ConsumerStatus.BLOCKED:
        return <RemoveCircleOutlineIcon color='error'/>
    }
  }

  const onHandleDelete = async (id: number) => {
    await consumerStore.deleteConsumer(id);

    if (!error) {
      showSnackbar('success', 'Consumer successfully deleted');
    }
  }

  const onHandleNotify = async (id: number) => {
    await consumerStore.sendPushNotificationToCustomer(id);

    if (!error) {
      showSnackbar('success', 'Notification has been sent');
    }
  }

  const onHandleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    consumerStore.consumerSearch = e.target.value as any as string
    await consumerStore.fetchCustomers();
  }

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Paper sx={{
        p: 2,
        height: 740,
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Typography component='h2' variant='h5' color='primary' gutterBottom >Consumers</Typography>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '5px 0'
        }}>
          <SearchInputField onChange={onHandleSearch} />
          <Button variant='contained' onClick={() => navigate('create') }>
            Create consumer
          </Button>
        </Box>
        <TableContainer>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Tenant</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Date Created</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? <TableRow>
                  <TableCell align='center' colSpan={8} >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
                : consumers.length
                  ? consumers.map((consumer) => (
                    <TableRow key={consumer.id}>
                      <TableCell>
                        <Avatar alt={consumer.email} src={consumer.photo} />
                      </TableCell>
                      <TableCell>{consumer.name}</TableCell>
                      <TableCell>{consumer.email}</TableCell>
                      <TableCell>{consumer.tenant?.name ?? '-'}</TableCell>
                      <TableCell>{getStatusIcon(consumer.status)}</TableCell>
                      <TableCell>{consumer.createdAt}</TableCell>
                      <TableCell sx={{ width: '5%' }}>
                        <AlertNotifyDialogSlide
                          size='small'
                          iconColor='info'
                          idx={consumer.id}
                          notifyHandler={onHandleNotify}
                          context={consumer.email} />
                      </TableCell>
                      <TableCell style={{ width: '5%' }}>
                        <IconButton
                          size='small'
                          onClick={() => navigate(`${consumer.id}`)}
                        >
                          <EditIcon fontSize='small' color='warning' />
                        </IconButton>
                      </TableCell>
                      <TableCell style={{ width: '5%' }}>
                        <AlertDeleteDialogSlide
                          size='small'
                          iconColor='error'
                          idx={consumer.id}
                          deleteHandler={onHandleDelete}
                          context={consumer.email} />
                      </TableCell>
                    </TableRow>
                  ))
                  : <TableRow>
                    <TableCell align='center' colSpan={8} >
                      <Typography component='h5' variant='h5' color='blueviolet' gutterBottom >Nothing found</Typography>
                    </TableCell>
                  </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 35]}
          component="div"
          count={consumerStore.meta.total}
          page={consumerStore.meta.currentPage}
          onPageChange={handleChangePage}
          rowsPerPage={consumerStore.meta.perPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Grid>
  )
}

export const ConsumerList = compose<{}, {}>(observer)(ConsumerListPure)
