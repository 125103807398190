import {makeAutoObservable} from "mobx";

export class DesignStore {
    design_logo: File | null = null;
    design_backgroundColor: string = '#ffffff';
    design_headerColor: string = '#29749f';
    design_textColor: string = '#000000';

    constructor() {
        makeAutoObservable(this);
    }
}
