import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Slide
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import NotificationsIcon from "@mui/icons-material/Notifications";

type Props = {
    size?: "small" | "large" | "medium",
    iconColor?: "secondary" | "success" | "inherit" | "action" | "warning" | "disabled" | "error" | "primary" | "info",
    idx: number,
    notifyHandler: (idx: number) => void,
    context: string
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function AlertNotifyDialogSlide({ idx, size, iconColor, notifyHandler, context }: Props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(false);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleApprove = async() => {
        await notifyHandler(idx)
        setOpen(false);
    }

    return (
        <div>
            <IconButton
                size={size}
                onClick={handleClickOpen}
            >
                <NotificationsIcon fontSize='small' color={iconColor ?? 'info'} />
            </IconButton>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Notification"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want push notification to: { context }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='error'>Disagree</Button>
                    <Button onClick={handleApprove} color='success'>Agree</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}