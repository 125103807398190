import { compose } from "../../utils/compose/compose";
import { observer } from "mobx-react";
import { Avatar, Box, Button } from '@mui/material';
import { DesignStore } from "../../modules/tenant/stores/design.store";
import { WritingStore } from '../../modules/tenant/stores/writing.store';
import { useState } from 'react';
import { autorun } from 'mobx';

type Props = {
    designStore: DesignStore,
    writingStore: WritingStore,
    attachedLogo?: string,
}

const SdkApplicationPure = ({ designStore, writingStore, attachedLogo }: Props) => {
    const [logo, setLogo] = useState<string>('')

    autorun(() => {
        if (designStore.design_logo) {
            const reader = new FileReader();
            reader.onload = (e) => {
              // @ts-ignore
              const file = e.target.result;
              // @ts-ignore
              setLogo(file)
            }
            reader.readAsDataURL(designStore.design_logo)
        }
    })

    return (
        // background
        <Box sx={{
            position: 'absolute',
            borderRadius: '38.6px',
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            backgroundColor: designStore.design_backgroundColor
        }}>
            <Avatar src={logo ? logo : attachedLogo } variant='rounded' sx={{
              margin: '40px 0 0 7px',
              position: 'absolute',
              justifyContent: 'center',
              alignItems: 'center',
              height: '45%',
              width: '95%',
            }}/>
            <Box sx={{
              marginTop: '350px',
              width: '100%',
              color: designStore.design_textColor
            }}>
              <Box
                component='div'
                dangerouslySetInnerHTML={{ __html: writingStore.writing_bodyText }}
              />
            </Box>
            <Box sx={{
              position: 'absolute',
              justifyContent: 'center',
              alignItems: 'center',
              bottom: '40px',
              width: '95%',
            }}>
                <Button sx={{
                  display: 'flex',
                  margin: '0 7px',
                  width: '100%',
                  marginBottom: '7px',
                  color: designStore.design_textColor,
                  backgroundColor: designStore.design_headerColor
                }} variant="contained">Sign up</Button>
                <Button sx={{
                  display: 'flex',
                  margin: '0 7px',
                  width: '100%',
                  color: designStore.design_textColor,
                  backgroundColor: designStore.design_headerColor
                }} variant="contained">Login</Button>
            </Box>
        </Box>
    )
}

export const SdkApplication = compose<Props, Props>(observer)(SdkApplicationPure)
