import { useRoutes } from 'react-router-dom';
import { SettingPage } from './pages/setting.page';
import { compose } from '../../utils/compose/compose';
import { observer } from 'mobx-react';

const SettingModulePure = () => {
  return useRoutes([
    {
      path: '/',
      index: true,
      Component: SettingPage,
    }
  ])
}

export const SettingModule = compose<{}, {}>(observer)(SettingModulePure)
