import { makeAutoObservable } from "mobx";
import { AuthService } from "../services/auth.service";
import { AuthApiNS } from "../services/auth.api.type";
import { inject } from 'react-ioc';
import { useContext } from 'react';
import { AuthContext } from '../contexts/auth.context';

export class AuthStore {
    auth = useContext(AuthContext);
    token = localStorage.getItem('token') || ''
    authService = inject<AuthService>(this, AuthService)

    constructor() {
        makeAutoObservable(this, undefined, { autoBind: true });
    }

    async requestLogin(body: AuthApiNS.LoginDTO) {
        const authModel = await this.authService.login(body);
        return authModel?.accessToken;
    }
}
