import { inject } from 'react-ioc';
import { AuthStore } from '../../auth/stores/auth.store';
import { apiClient } from '../../../utils/api-client/api-client';
import { AxiosResponse } from 'axios';
import { ScheduleConfigApiNS } from './schedule-config.api';

export class ScheduleConfigService {
  private readonly authStore = inject<AuthStore>(this, AuthStore)

  async get(tenantId: number) {
    const res = await apiClient.get<
      '',
      AxiosResponse<ScheduleConfigApiNS.ScheduleConfigDTO>
    >(
      `/api/v1/tenants/${tenantId}/schedule-config`,
      {
        headers: {
          'Authorization': `Bearer ${this.authStore.token}`
        }
      }
    );

    return res.data;
  }

  async update(tenantId: number, data: ScheduleConfigApiNS.ScheduleConfigDTO) {
    await apiClient.put<ScheduleConfigApiNS.ScheduleConfigDTO>(
      `/api/v1/tenants/${tenantId}/schedule-config`,
      data,
      {
        headers: {
          'Authorization': `Bearer ${this.authStore.token}`
        }
      }
    );
  }
}
