import React, { FC, useEffect } from 'react';
import { compose } from '../../../../utils/compose/compose';
import { observer } from 'mobx-react';
import Dialog from '@mui/material/Dialog';
import {
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  IconButton,
  Tooltip,
  IconButtonProps,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Switch,
  Grid,
} from '@mui/material';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ScheduleConfigApiNS } from '../../services/schedule-config.api';
import { useInstance } from 'react-ioc';
import { ScheduleConfigStore } from '../../stores/schedule-config.store';
import { useSnackbar } from '../../../../common/providers/snackbar.provider';

export interface ScheduleConfigDialogProps {
  size?: IconButtonProps["size"],
  iconColor?: "secondary" | "success" | "inherit" | "action" | "warning" | "disabled" | "error" | "primary" | "info",
  idx: number,
  context: string
}

const ScheduleConfigDialogPure: FC<ScheduleConfigDialogProps> = ( { idx, size, iconColor, context }: ScheduleConfigDialogProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const scheduleConfigStore = useInstance<ScheduleConfigStore>(ScheduleConfigStore);
  const showSnackbar = useSnackbar()
  const { error, isLoading } = scheduleConfigStore;

  const formik = useFormik({
    initialValues: {
      type: 'TIMESPAN',
      surfTimeInMinutesUntilNextCampaignIsPushed: 0,
      timespanInMinutesUntilNextCampaignIsPushed: 0,
      gracePeriodInMinutesToWatchCampaign: 0,
      enabled: false,
    },
    validationSchema: Yup.object({
      type: Yup.mixed().oneOf(Object.values(ScheduleConfigApiNS.ScheduleConfigType)),
      surfTimeInMinutesUntilNextCampaignIsPushed: Yup.number(),
      timespanInMinutesUntilNextCampaignIsPushed: Yup.number(),
      gracePeriodInMinutesToWatchCampaign: Yup.number(),
      enabled: Yup.boolean(),
    }),
    onSubmit: async (params: any, helpers) => {
      const data: ScheduleConfigApiNS.ScheduleConfigDTO = {
        type: params.type,
        surfTimeInMinutesUntilNextCampaignIsPushed: parseInt(params.surfTimeInMinutesUntilNextCampaignIsPushed),
        timespanInMinutesUntilNextCampaignIsPushed: parseInt(params.timespanInMinutesUntilNextCampaignIsPushed),
        gracePeriodInMinutesToWatchCampaign: parseInt(params.gracePeriodInMinutesToWatchCampaign),
        enabled: params.enabled,
      }
      await scheduleConfigStore.updateScheduleConfigurations(idx, data)
      handleClose()
    }
  });

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      (async () => {
        await scheduleConfigStore.fetchScheduleConfigurations(idx);
        const scheduleConfigs = scheduleConfigStore.scheduleConfigs;

        if (scheduleConfigs) {
          await formik.setFieldValue('type', scheduleConfigs.type);
          await formik.setFieldValue('surfTimeInMinutesUntilNextCampaignIsPushed', scheduleConfigs.surfTimeInMinutesUntilNextCampaignIsPushed);
          await formik.setFieldValue('timespanInMinutesUntilNextCampaignIsPushed', scheduleConfigs.timespanInMinutesUntilNextCampaignIsPushed);
          await formik.setFieldValue('gracePeriodInMinutesToWatchCampaign', scheduleConfigs.gracePeriodInMinutesToWatchCampaign);
          await formik.setFieldValue('enabled', scheduleConfigs.enabled);
        }
      })()
    }
  }, [open])

  useEffect(() => {
    if (error) {
      showSnackbar('error', error);
    }
  }, [error]);

  return (
    <React.Fragment>
      <Tooltip title='Schedule configurations'>
        <IconButton
          size='small'
          onClick={handleClickOpen}
        >
          <ManageHistoryIcon fontSize='small' color='primary' />
        </IconButton>
      </Tooltip>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Schedule configurations for {context}</DialogTitle>
        <form
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <InputLabel sx={{
                  marginTop: '10px',
                  display: "flex",
                  fontWeight: 700
                }}>Type</InputLabel>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Select
                  id="type"
                  name="type"
                  value={formik.values.type}
                  error={!!(formik.touched.type && formik.errors.type)}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  label="Type"
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  disabled={isLoading}
                  sx={{ width: '25ch' }}
                >
                  {Object.entries(ScheduleConfigApiNS.ScheduleConfigType).map(([key, value]) => {
                    return <MenuItem value={value}>{key}</MenuItem>
                  })}
                </Select>
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputLabel sx={{
                  marginTop: '10px',
                  display: "flex",
                  fontWeight: 700
                }}>Surf time</InputLabel>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  label="SurfTime"
                  id="surfTimeInMinutesUntilNextCampaignIsPushed"
                  name="surfTimeInMinutesUntilNextCampaignIsPushed"
                  sx={{ width: '25ch' }}
                  value={formik.values.surfTimeInMinutesUntilNextCampaignIsPushed}
                  error={!!(formik.touched.surfTimeInMinutesUntilNextCampaignIsPushed && formik.errors.surfTimeInMinutesUntilNextCampaignIsPushed)}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">min</InputAdornment>,
                  }}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputLabel sx={{
                  marginTop: '10px',
                  display: "flex",
                  fontWeight: 700
                }}>Timespan</InputLabel>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  label="Timespan"
                  id="timespanInMinutesUntilNextCampaignIsPushed"
                  name="timespanInMinutesUntilNextCampaignIsPushed"
                  sx={{ width: '25ch' }}
                  value={formik.values.timespanInMinutesUntilNextCampaignIsPushed}
                  error={!!(formik.touched.timespanInMinutesUntilNextCampaignIsPushed && formik.errors.timespanInMinutesUntilNextCampaignIsPushed)}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">min</InputAdornment>,
                  }}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputLabel sx={{
                  marginTop: '10px',
                  display: "flex",
                  fontWeight: 700
                }}>Grace period</InputLabel>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  label="Grace period"
                  id="gracePeriodInMinutesToWatchCampaign"
                  name="gracePeriodInMinutesToWatchCampaign"
                  sx={{ width: '25ch' }}
                  value={formik.values.gracePeriodInMinutesToWatchCampaign}
                  error={!!(formik.touched.gracePeriodInMinutesToWatchCampaign && formik.errors.gracePeriodInMinutesToWatchCampaign)}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">min</InputAdornment>,
                  }}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputLabel sx={{
                  marginTop: '10px',
                  display: "flex",
                  fontWeight: 700
                }}>Enabled</InputLabel>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Switch
                  checked={formik.values.enabled}
                  id="enabled"
                  name="enabled"
                  color="primary"
                  onBlur={formik.handleBlur}
                  value={formik.values.enabled}
                  onChange={formik.handleChange}
                  disabled={scheduleConfigStore.isLoading}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type='submit'>Save</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  )
}

export const ScheduleConfigDialog = compose<ScheduleConfigDialogProps, ScheduleConfigDialogProps>(observer)(ScheduleConfigDialogPure);
