import React, { Component, ErrorInfo } from 'react';


interface Props {
    children?: React.ReactNode,
}
interface State {
    error?: Error;
    errorInfo?: ErrorInfo;
}

export class ErrorGuard extends Component<Props, State> {
    state: State = {};

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({ error, errorInfo });
    }

    render() {
        if (this.state.error) {
            return (
                <>
                    <div>Error message: {this.state.error.message}</div>
            <div>ComponentStack: {this.state.errorInfo?.componentStack}</div>
            </>
        );
        }
        return this.props.children;
    }
}
